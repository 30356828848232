import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Headline from '@objects/headline'
import TopicTeaser from '@objects/topicTeaser'
import { focalPointsTable } from '@tools/focalPoints'

const useStyles = makeStyles((theme) => ({
  headline: {
    marginBottom: theme.spacing(15),
    textAlign: 'center',
  },
}))

function TopicTeaserGrid({ headline, headlineLevel, topics, gridMap, shadow }) {
  const classes = useStyles()
  const map = gridMap

  function renderTopics() {
    return topics
      .filter(
        (topic) =>
          !!topic.slug && !!topic.image && !!topic.headline && !!topic.copy
      )
      .map((topic, i) => (
        <Grid
          key={`item${i}`}
          item
          xs={12}
          sm={6}
          lg={map[i % 7]}
          role="menuitem"
          aria-label={topic.headline}
        >
          <TopicTeaser
            image={topic.image}
            imageAlt={topic.imageAlt}
            headline={topic.headline}
            copy={topic.copy}
            focalPoint={topic.focalPoint}
            slug={topic.slug}
            truncateCopy={map[i % 7] * 4}
            shadow={shadow}
            url={topic.url}
          />
        </Grid>
      ))
  }

  return (
    <>
      <Headline level={headlineLevel} className={classes.headline}>
        {headline}
      </Headline>
      <Grid container className={classes.root} spacing={8} role="group">
        {renderTopics()}
      </Grid>
    </>
  )
}

TopicTeaserGrid.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  headlineLevel: PropTypes.number,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      image: PropTypes.object,
      focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
      imageAlt: PropTypes.string,
      headline: PropTypes.string,
      copy: PropTypes.string,
      truncateCopy: PropTypes.number,
    })
  ),
  gridMap: PropTypes.arrayOf(PropTypes.number),
  shadow: PropTypes.bool,
}

TopicTeaserGrid.defaultProps = {
  headlineLevel: 2,
  gridMap: [8, 4, 5, 4, 3, 4, 8],
  shadow: true,
}

export default TopicTeaserGrid
