import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  sourcesRoot: {
    paddingLeft: theme.spacing(5),
  },
  headline: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(7),
    },
  },
  item: {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  label: {
    ...theme.typography.link,
  },
}))

function Sources({ className, items }) {
  const classes = useStyles()

  return (
    <ol className={classes.sourcesRoot}>
      {items.map((item, i) => (
        <li className={classes.item} key={i}>
          <a
            data-track-content
            data-tracking-id={item.label}
            className={classes.label}
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            {item.label}
          </a>{' '}
          {item.description}
        </li>
      ))}
    </ol>
  )
}

Sources.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}
export default Sources
