import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Zoom, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import queryString from 'query-string'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from '@reach/router'
import clipboardCopy from 'clipboard-copy'

import LikeButton from '@objects/button/like'
import SocialIcon from '@objects/socialicon'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  horizontal: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(8),
    },
    '& $shareIcon': {
      display: 'inline-block',
    },
    '& $like': {
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        left: 0,
        marginTop: 0,
      },
    },
    '& > div': {
      marginRight: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(8),
      },
    },
  },
  vertical: {
    '& $shareIcon': {
      marginBottom: theme.spacing(6),
    },
    '& $comments': {
      marginTop: theme.spacing(6),
    },
    '& $like': {
      marginTop: theme.spacing(14),
    },
  },
  shareIcon: {
    color: theme.palette.grey.dark,
    cursor: 'pointer',
    '& svg': {
      fontSize: '2.5rem',
    },
  },
  like: {},
  comments: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    padding: theme.spacing(2.5),
    border: 0,
    borderRadius: '50%',
    background: theme.palette.grey.dark,
    color: theme.palette.text.invert,
    marginRight: theme.spacing(1.5),
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(3),
    },
    '&:focus': {
      outline: 'none',
      background: theme.palette.background.focus,
    },
  },
  copiedMessageBox: {
    width: 'auto',
    display: 'inline-block',
    maxWidth: '100px',
  },
}))

function SharingBar({ ...props }) {
  const classes = useStyles()
  const intl = useIntl()
  const { className, type, pageContext, like, comments } = props
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const winLocation = useLocation()

  const location = winLocation?.href ?? pageContext?.canonical

  const twitterQueryString = queryString.stringify({
    text: intl.formatMessage({ id: 'sharing.twitter.template' }),
    url: location,
    hashtags: intl.formatMessage({ id: 'sharing.twitter.hashtags' }),
  })

  const linkClick = () => {
    clipboardCopy(location.href).then(() => {
      setTooltipOpen(() => true)
    })
    setTimeout(() => {
      setTooltipOpen(() => false)
    }, 2000)
  }

  const commentsClick = () => {
    const el = document.querySelector(`#${comments.id}`)
    if (el) {
      window.scrollTo({
        top: el.getBoundingClientRect().y + window.scrollY - 100,
        behavior: 'smooth',
      })
    }
  }

  const renderTooltipHtml = () => {
    return (
      <div className={classes.copiedMessageBox}>
        <FormattedMessage id={'sharing.link.copied'} />
      </div>
    )
  }

  return (
    <div className={clsx(className, classes[type])}>
      <div className={classes.shareIcon}>
        <SocialIcon
          className={'twitter-share-button'}
          item={{
            href: `https://twitter.com/intent/tweet?${twitterQueryString}`,
            name: 'tw-icon',
          }}
          name={'Twitter'}
        />
      </div>
      <div className={classes.shareIcon}>
        <Tooltip
          title={renderTooltipHtml()}
          interactive
          TransitionComponent={Zoom}
          placement="bottom"
          disableHoverListener
          open={tooltipOpen}
        >
          <Box component={'div'}>
            <SocialIcon
              item={{
                onClick: linkClick,
                name: 'copy-icon',
              }}
              name={'Link'}
            />
          </Box>
        </Tooltip>
      </div>
      {like && (
        <LikeButton
          className={classes.like}
          type="article"
          targetId={like.id}
          likeCount={like.count}
          interactive={true}
          countAlign="right"
          circle
        />
      )}
      {comments && (
        <div className={classes.comments}>
          <button className={classes.icon} onClick={commentsClick}>
            <Icon name={'Contribute'} />
          </button>
          <span>{comments.count}</span>
        </div>
      )}
    </div>
  )
}

SharingBar.propTypes = {
  type: PropTypes.oneOf(['horizontal', 'vertical']),
  className: PropTypes.string,
  pageContext: PropTypes.object.isRequired,
  fbQuote: PropTypes.string,
  like: PropTypes.shape({
    id: PropTypes.number,
    count: PropTypes.number,
  }),
  comments: PropTypes.shape({
    id: PropTypes.string,
    count: PropTypes.number,
  }),
}

SharingBar.defaultProps = {
  type: 'horizontal',
}

export default SharingBar
