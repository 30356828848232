import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Icon from '@objects/icon'
import { focalPointsTable } from '@tools/focalPoints'

const useStyles = makeStyles((theme) => ({
  topicTeaserRoot: {
    '&:hover $imageInner, &:focus $imageInner': {
      top: theme.spacing(-1),
      left: theme.spacing(-1),
      bottom: theme.spacing(-1),
      right: theme.spacing(-1),
      height: 'auto',
    },
    '&:hover $shadow, &:focus $shadow': {
      boxShadow: theme.customShadow.topicTeaserHover,
    },
  },
  imageWrapper: {
    position: 'relative',
    height: theme.spacing(61),
    marginBottom: theme.spacing(5),
  },
  imageInner: {
    transitionProperty: 'all',
    transitionDuration: theme.transitions.duration.shorter,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  image: {
    height: '100%',
  },
  shadow: {
    boxShadow: theme.customShadow.topicTeaser,
  },
  headline: {
    ...theme.typography.h5,
    marginBottom: theme.spacing(1),
  },
  copy: {
    marginBottom: theme.spacing(2),
    ...theme.typography.teaser,
  },
  headlineWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  externalIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
    marginLeft: '5px',
  },
}))

function TopicTeaser({
  className,
  slug,
  image,
  imageAlt,
  headline,
  copy,
  truncateCopy,
  shadow,
  focalPoint,
  url,
}) {
  const classes = useStyles()
  const focalPoints = focalPointsTable

  function isURLExternal(url) {
    if (!!!url) {
      return false
    }
    return url.match(/^((http[s]?|ftp|mailto|tel|fax|javascript):)/)
  }

  return (
    <Link
      data-track-content
      data-tracking-id={slug}
      className={clsx(className, classes.topicTeaserRoot)}
      to={slug}
      aria-label={`Link - ${headline}`}
    >
      {image && (
        <div className={classes.imageWrapper}>
          <div
            className={clsx(classes.imageInner, {
              [classes.shadow]: shadow,
            })}
          >
            <Img
              className={classes.image}
              fluid={{ ...image, media: `(min-width: 0px)` }}
              backgroundColor={true}
              alt={imageAlt}
              objectPosition={
                focalPoint ? focalPoints[focalPoint] : focalPoints['Center']
              }
            />
          </div>
        </div>
      )}
      <div className={classes.headlineWrapper}>
        <Headline level={3} className={classes.headline}>
          {headline}
        </Headline>

        {isURLExternal(url) && (
          <Icon
            className={classes.externalIcon}
            name={'TextExternalArrow'}
            size="inherit"
          />
        )}
      </div>
      {copy && (
        <Copy className={classes.copy} html={copy} truncate={truncateCopy} />
      )}
    </Link>
  )
}

TopicTeaser.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
  truncateCopy: PropTypes.number,
  shadow: PropTypes.bool,
  focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
}
export default TopicTeaser
