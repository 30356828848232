import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'
import { ArticleJsonLd, SpeakableJsonLd } from 'gatsby-plugin-next-seo'
import useDateFns from '@hooks/useDateFns'

import _ from 'lodash'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import api from '@api'
import {
  ANSWER_SUBMIT_SUCCESS,
  COMMENT_SUBMIT_SUCCESS,
  eventBus,
} from '@services/eventBus'

import { GlossaryScopeProvider } from '@providers/glossaryScopeProvider'
import ContentfulComponentArray from '@tools/ContentfulComponentArray'
import Container from '@objects/container'
import Stage from '@components/stage'
import Copy from '@objects/copy'
import Headline from '@objects/headline'
import Sources from '@objects/sources'
import TopicTeaserGrid from '@components/topicTeaserGrid'
import SharingBar from '@components/sharingBar'
import Separator from '@objects/separator'
import CreateContentButton from '@objects/button/createContentButton'
import PostCard from '@components/postCard'
import Card from '@objects/card'

const useStyles = makeStyles((theme) => ({
  article: {
    marginBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(20),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(30),
    },
  },
  shareBarTopContainer: {
    position: 'relative',
  },
  shareBarTop: {
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(-24),
    },
    [theme.breakpoints.up('xl')]: {
      left: theme.spacing(-36),
    },
  },
  shareBarBottomContainer: {},
  shareBarBottom: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(-7),
    },
  },
  answerCard: {
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(-4),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  commentCount: {
    marginBottom: theme.spacing(5),
  },
  shareBarBottomCopy: {
    textAlign: 'center',
    marginBottom: theme.spacing(8),
  },
}))

function MagazineArticle({ data, pageContext, pathContext }) {
  const intl = useIntl()
  const theme = useTheme()
  const classes = useStyles()
  const location = useLocation()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const { formatDate } = useDateFns()

  const createdAt =
    data.contentfulArticle?.date ?? data.contentfulArticle?.createdAt
  const slug = data.contentfulArticle?.slug
  const stageImage = data.contentfulArticle?.stageImageWithFocalpoint
    ? data.contentfulArticle?.stageImageWithFocalpoint.image
    : data.contentfulArticle?.stageImage
  const stageImageMobile = data.contentfulArticle?.stageImageMobile
  const stageImageFocalPoint =
    data.contentfulArticle?.stageImageWithFocalpoint?.focalPoint
  const articletitle = data.contentfulArticle?.title
  const author = data.contentfulArticle?.author
  const authorDescription = data.contentfulArticle?.authorDescription
  const authorImage = data.contentfulArticle?.authorImage
  const articlecontent = data.contentfulArticle?.copy?.json?.content
  const articleintro =
    data.contentfulArticle?.introCopy?.childMarkdownRemark?.html
  const articlesources = data.contentfulArticle?.sources
  const relatedArticles = data.contentfulArticle?.relatedArticles
  const topic___category = data.contentfulArticle?.topic___category
  const articleQuote = data.contentfulArticle?.ogDescription

  const topicCategory = topic___category?.length
    ? topic___category[0]?.title
    : ''

  const articleRequest = api().getMagazineArticle
  const [articleState, setArticleState] = useState({
    id: null,
    numberOfLikes: 0,
    answers: [],
  })

  const relatedArticlesMapped = relatedArticles
    ?.filter(
      (filterArticle) =>
        (filterArticle.title && filterArticle.slug) || filterArticle.url
    )
    ?.map((article) => {
      const cat = !!article.topic___category ? article.topic___category : null
      const path =
        cat === null
          ? intl.formatMessage({ id: 'press.article.path' }) // DEPRECATED
          : cat[0].topic___category___list?.[0].identifier === 'magazin'
          ? intl.formatMessage({ id: 'magazine.path' })
          : `${intl.formatMessage({ id: 'inform.path' })}${cat[0].slug}/`

      return {
        headline: article?.title,
        copy: article.teaserCopy?.childMarkdownRemark?.html,
        image: article.teaserImageWithFocalpoint
          ? article.teaserImageWithFocalpoint.image.fluid
          : article.teaserImage?.fluid,
        imageAlt: article.teaserImageWithFocalpoint
          ? article.teaserImageWithFocalpoint.image.description
          : article.teaserImage?.description,
        focalPoint: article.teaserImageWithFocalpoint?.focalPoint,
        slug: article.slug ? `${path}${article.slug}/` : article.url,
        url: !!article.url && article.url,
      }
    })

  const getArticle = (forceReload) => {
    if (!articleState.post || forceReload) {
      return articleRequest(slug, articletitle)
        .then((response) => {
          setArticleState((articleState) => ({
            ...articleState,
            id: response.data.id,
            numberOfLikes: response.data.numberOfLikes,
            answers: response.data.answers,
          }))
          return response
        })
        .catch((err) => {
          //todo return/redirect 404
          console.error('get single post error:', err.message)
        })
    }
  }

  const onAnswerSuccessCallback = (data) => {
    getArticle({ forceReload: true, answerId: data?.answerId })
      .then(() => {
        scrollToId(
          `postcard-comment-${data?.commentId}`,
          `answer-${data?.answerId}-control`
        )
      })
      .catch((e) => console.error(e))
  }
  const onCommentSuccessCallback = (data) => {
    getArticle({
      forceReload: true,
      commentId: data?.commentId,
      answerId: data?.answerId,
    })
      .then(() => {
        scrollToId(
          `postcard-comment-${data?.commentId}`,
          `answer-${data?.answerId}-control`
        )
      })
      .catch((e) => console.error(e))
  }

  const scrollToId = (idSelector, controlId) => {
    setTimeout(() => {
      if (controlId) {
        // eslint-disable-next-line no-unused-expressions
        document.getElementById(controlId)?.click()
      }
      const element = document.getElementById(idSelector)
      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element
          ? element.getBoundingClientRect().top + window.pageYOffset - 32
          : 0,
      })
    }, 500)
  }

  useEffect(() => {
    const query = queryString.parse(location.search) || {}

    getArticle(true)
      .then((response) => {
        // after first load
        if (query.commentid && query.answerid) {
          //scroll to opt.answer
          scrollToId(
            `postcard-comment-${query.commentid}`,
            `answer-${query.answerid}-control`
          )
        } else if (query.answerid) {
          //scroll to opt.comment
          scrollToId(`postcard-answer-${query.answerid}`)
        }
      })
      .catch((e) => console.error(e))
  }, [location])

  useEffect(() => {
    eventBus.on(ANSWER_SUBMIT_SUCCESS, onAnswerSuccessCallback)
    eventBus.on(COMMENT_SUBMIT_SUCCESS, onCommentSuccessCallback)
    return () => {
      eventBus.remove(ANSWER_SUBMIT_SUCCESS, onAnswerSuccessCallback)
      eventBus.remove(COMMENT_SUBMIT_SUCCESS, onCommentSuccessCallback)
    }
  }, [])

  return (
    <React.Fragment>
      <ArticleJsonLd
        url={pathContext.canonical}
        headline={articletitle}
        datePublished={createdAt}
        overrides={{
          '@type': 'NewsArticle',
        }}
      />
      <SpeakableJsonLd cssSelector={['article']} />
      <Stage
        image={stageImage}
        imageMobile={stageImageMobile}
        focalPoint={stageImageFocalPoint}
        title={articletitle}
        ariaLabel={articletitle}
        subtitle={topicCategory}
        author={
          author
            ? {
                name: author,
                description: authorDescription,
                avatar: authorImage?.file?.url,
              }
            : null
        }
        date={formatDate(createdAt, 'dd.MM.yyyy')}
      />
      <article>
        <Container width="sm" className={classes.shareBarTopContainer}
        ariaLabel="arialabel.article"
        >
          <SharingBar
            type={isLarge ? 'vertical' : 'horizontal'}
            className={classes.shareBarTop}
            pageContext={pageContext}
            fbQuote={articleQuote}
            like={{ id: articleState.id, count: articleState.numberOfLikes }}
            comments={{ id: 'comments', count: articleState.answers.length }}
          />
        </Container>
        <div className={classes.article}>
          {articleintro && (
            <Container width="sm">
              <Copy type="intro">{articleintro}</Copy>
            </Container>
          )}
          {articlecontent && (
            <GlossaryScopeProvider>
              <ContentfulComponentArray componentarray={articlecontent} />
            </GlossaryScopeProvider>
          )}
          {articlesources && (
            <Container type="article" width="sm">
              <Headline>
                {intl.formatMessage({ id: 'article.sources' })}
              </Headline>
              <Sources items={articlesources} />
            </Container>
          )}
        </div>
        <Copy className={classes.shareBarBottomCopy}>
          {intl.formatMessage({ id: 'sharing.copy' })}
        </Copy>
        <Container width="sm" className={classes.shareBarBottomContainer}>
          <SharingBar
            className={classes.shareBarBottom}
            pageContext={pageContext}
            fbQuote={articleQuote}
            like={{ id: articleState.id, count: articleState.numberOfLikes }}
          />
          <Separator />
        </Container>

        <Container id="comments" width="sm">
          <Headline>
            {intl.formatMessage({ id: 'magazine.headline.commentSection' })}
          </Headline>
          <Card
            className={classes.answerCard}
            bar={'grey'}
            barOrientation={'right'}
          >
            <CreateContentButton
              color={'outlineGrey'}
              icon="Pen"
              fullwidth
              contentType="createAnswer"
              articleId={articleState.id}
              onSuccessCallback={onAnswerSuccessCallback}
            >
              <FormattedMessage id={'dialogue.createAnswer'} />
            </CreateContentButton>
          </Card>

          <div className={classes.commentCount}>
            <FormattedMessage
              id={'dialogue.allAnswersCount'}
              values={{ count: articleState.answers.length }}
            />
          </div>

          {articleState.answers &&
            articleState.answers.map((answer) => {
              return (
                <PostCard
                  bar={answer.isExpertAnswer ? 'yellow' : 'grey'}
                  background={answer.isExpertAnswer ? 'dark' : 'default'}
                  postData={{
                    likeCount: answer.numberOfLikes,
                    replyCount: answer.comments.length,
                    userName: answer.userName,
                    userColor: answer.userColor,
                    timestamp: answer.timestamp,
                    isDeleted: answer.isDeleted,
                    topics: answer.topics,
                    title: answer.title,
                    text: answer.text,
                    isExpert: answer.isExpertAnswer,
                    id: answer.id,
                    comments: answer.comments,
                  }}
                  type={'answer'}
                  key={_.uniqueId('answer-')}
                  interactive={true}
                />
              )
            })}
        </Container>

        {relatedArticles && (
          <Container
            padded
            background={theme.palette.background.grey}
            ariaLabel="arialabel.relatedArticles"
          >
            <TopicTeaserGrid
              headline={intl.formatMessage({
                id: 'article.relatedArticles',
              })}
              headlineLevel={3}
              topics={relatedArticlesMapped}
              gridMap={
                relatedArticles.length === 2
                  ? [6, 6, 6, 6, 6, 6, 6]
                  : [4, 4, 4, 4, 4, 4, 4]
              }
              shadow={false}
            />
          </Container>
        )}
      </article>
    </React.Fragment>
  )
}

MagazineArticle.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default MagazineArticle

export const pageQuery = graphql`
  query MagazineArticleBySlug(
    $slug: String!
    $locale: String!
    $contentful_id: String!
  ) {
    SlugByContentfulId: contentfulArticle(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $locale }
    ) {
      slug
      node_locale
      fields {
        fullPath
      }
      topic___category {
        slug
      }
    }
    contentfulArticle(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      createdAt
      date
      slug
      title
      introCopy {
        childMarkdownRemark {
          html
        }
      }
      copy {
        json
      }
      sources {
        description
        label
        url
      }
      topic___category {
        title
      }
      stageImage {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
        title
      }
      stageImageMobile {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
        title
      }
      stageImageWithFocalpoint {
        image {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
          title
        }
        focalPoint
      }
      ogDescription
      relatedArticles {
        ... on ContentfulArticle {
          id
          slug
          title
          updatedAt
          teaserCopy {
            childMarkdownRemark {
              html
            }
          }
          teaserImage {
            fluid(maxWidth: 604, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          teaserImageWithFocalpoint {
            image {
              fluid(maxWidth: 704, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            focalPoint
          }
          topic___category {
            slug
            topic___category___list {
              identifier
            }
          }
        }
        ... on ContentfulPageExternal {
          id
          title
          url
          teaserTitle
          teaserCopy {
            childMarkdownRemark {
              html
            }
          }
          teaserImage {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
            title
          }
          teaserImageWithFocalpoint {
            image {
              fluid(maxWidth: 704, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            focalPoint
          }
        }
      }
    }
  }
`
